import React from 'react';
import * as Sentry from '@sentry/react';
import { jwtDecode } from 'jwt-decode';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import ApiTokenPayload from '@/interfaces/apiTokenPayload';
import { useAuthStore } from '@/store/authStore';

const env = window.__env__.ENV;

function setSentryUserFromToken(token: string | null) {
  if (token) {
    const tokenData = jwtDecode<ApiTokenPayload>(token);

    Sentry.setUser({
      email: tokenData.ul,
      accountId: tokenData.ai,
      userId: tokenData.ui,
    });
  } else {
    Sentry.setUser(null);
  }
}

if (env === 'production' || env === 'staging') {
  Sentry.init({
    dsn: 'https://3ef489e5b1741f27f408ea90146c2a5e@sentry.fu.do/18',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: env,
    replaysSessionSampleRate: env === 'production' ? 0.1 : 1,
    replaysOnErrorSampleRate: 1.0,
  });

  const initialState = useAuthStore.getState();

  setSentryUserFromToken(initialState.token);

  useAuthStore.subscribe((state, prevState) => {
    if (state.token === prevState.token) return;
    setSentryUserFromToken(state.token);
  });
}
