import * as Sentry from '@sentry/react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { postMessage } from '../helpers/iframe';
import { useAuthStore } from '../store/authStore';
import { deserialize } from './jsonApi';

const apiClient = axios.create({
  baseURL: window.__env__.BASE_URL,
});

const authApiClient = axios.create({
  baseURL: window.__env__.BASE_AUTH_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    const { token, pusherSocketId } = useAuthStore.getState();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['pusher-socket-id'] = pusherSocketId;
      config.headers['fudo-request-id'] = uuidv4();
      config.headers['fudo-app-version'] = import.meta.env.VITE_BUILD_VERSION;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

apiClient.interceptors.response.use(
  (response) => {
    if (response.headers['content-type']?.includes('json')) {
      response.data = response.data['atomic:results'] || deserialize(response.data);
    }

    return response;
  },
  (error) => {
    if (error.response?.status === 400) {
      Sentry.captureException(error);
    }

    if (error.response?.status === 401) {
      postMessage('logout', {});
      useAuthStore.getState().logout();
    }

    return Promise.reject(error);
  },
);

export { apiClient, authApiClient };
