import { useEffect } from 'react';

import { DEFAULT_ES_LOCALES } from '@/constants/defaultEsLocales';
import { Account } from '@/interfaces/account';

import i18n from '../libs/i18n';

const useLanguage = (account: Account | undefined) => {
  useEffect(() => {
    if (!account) return;

    const availableLocales = i18n.options.supportedLngs || [];
    const navigatorLanguage = navigator.language;
    const accountLanguage = `es-${account.countryCode.toUpperCase()}`;

    const hasDefaultLocale = DEFAULT_ES_LOCALES.includes(navigatorLanguage);
    const accountLanguageSupported = availableLocales.includes(accountLanguage);

    if (hasDefaultLocale && accountLanguageSupported) {
      i18n.changeLanguage(accountLanguage);
    }
  }, [account]);
};

export default useLanguage;
