import Jsona from 'jsona';

const jsona = new Jsona();

export type SerializedData = {
  type: string;
  data: object;
};

const deserialize = (data: object) => jsona.deserialize(data);

const serialize = ({ type, data }: SerializedData) => {
  return jsona.serialize({ stuff: { type, ...data } });
};

export { deserialize, serialize };
