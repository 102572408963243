import { useQuery } from '@tanstack/react-query';

import { getAccount } from '../services/accountService';

const useGetAccount = () => {
  return useQuery({
    queryKey: ['account'],
    queryFn: () => getAccount(),
  });
};

export { useGetAccount };
